import Async from '../../../public/components/Async'
import { PageRoute } from '../../../public/module'
import AdminLayout from '../layout/AdminLayout'
import ContentLayout from '../layout/ContentLayout'

const routes: PageRoute[] = [
  {
    path: '/login',
    component: Async(import('../page/login/index')),
  },
  {
    path: '/bind/google-token/:user',
    component: Async(import('../page/login/BindGoogleToken'))
  },
  {
    path: '/*',
    layout: AdminLayout,
    children: [
      {
        path: '/',
        component: Async(import('../page/home/index')),
      },
      {
        path: '/sys/*',
        layout: ContentLayout,
        children: [
          { path: '/menu', component: Async(import('../page/sys/menu')) },
          { path: '/role', component: Async(import('../page/sys/role')) },
          { path: '/user', component: Async(import('../page/sys/user')) },
          { path: '/config', component: Async(import('../page/sys/config')) },
        ],
      },
      {
        path: '/brand/*',
        layout: ContentLayout,
        children: [
          { path: '/list', component: Async(import('../page/brand')) },
          { path: '/balance/records', component: Async(import('../page/brand/BrandBalanceRecords')) },
          { path: '/withdraw/limit', component: Async(import('../page/brand/UserWithdrawLimit')) },
        ],
      },
      {
        path: '/game/*',
        layout: ContentLayout,
        children: [
          { path: '/list', component: Async(import('../page/game/list')) },
          { path: '/vendors', component: Async(import('../page/game/vendors')) },
          { path: '/supplier', component: Async(import('../page/game/supplier')) },
        ],
      },
      {
        path: '/payment/*',
        layout: ContentLayout,
        children: [
          { path: '/way', component: Async(import("../page/payment/way")) },
          { path: '/supplier', component: Async(import("../page/payment/supplier")) },
          { path: '/withdarw', component: Async(import("../page/payment/withdraw")) },
        ],
      },
      {
        path: '/site/*',
        layout: ContentLayout,
        children: [
          { path: '/banner', component: Async(import("../page/site/Banner")) },
          { path: '/section', component: Async(import("../page/site/Section")) },
          { path: '/deposit', component: Async(import("../page/site/DepositConfig")) },
          { path: '/withdraw', component: Async(import("../page/site/WithdrawConfig")) },
          { path: '/customer-service', component: Async(import("../page/site/CustomerService")) },
        ],
      },
      {
        path: '/center/:key',
        component: Async(import('../page/center/index')),
      },
      {
        path: '/group/*',
        layout: ContentLayout,
        children: [
          { path: '/list', component: Async(import('../page/group/index')) },

        ],
      },
      {
        path: '/message/*',
        layout: ContentLayout,
        children: [
          { path: '/notice', component: Async(import('../page/message/Notice')) },

        ],
      },
      {
        path: '/activity/*',
        layout: ContentLayout,
        children: [
          { path: '/lottery', component: Async(import("../page/activity/LotteryIndex")) },
          { path: '/deposit', component: Async(import("../page/activity/DepositIndex")) },
        ],
      },
      {
        path: "/robot/*",
        layout: ContentLayout,
        children: [
          { path: "/list", component: Async(import("../page/robot/index")) },

        ]
      },
      {
        path: "/setting/*",
        layout: ContentLayout,
        children: [
          { path: "/platform", component: Async(import("../page/setting/platform")) },
          { path: "/gateway", component: Async(import("../page/setting/gateway")) },
          { path: "/locale", component: Async(import("../page/setting/locale")) }, 
          { path: "/usdt-rate", component: Async(import("../page/setting/usdt-rate")) },
        ]
      },
      {
        path: "/pawn/comb/*",
        layout: ContentLayout,
        children: [
          { path: "/list", component: Async(import("../page/pawn/comb")) },

        ]
      },
      {
        path: "/order/*",
        layout: ContentLayout,
        children: [
          { path: "/deposit", component: Async(import("../page/order/DepositOrder")) },
          { path: "/withdraw", component: Async(import("../page/order/WithdrawOrder")) },
        ]
      },
      {
        path: "/statistics/*",
        layout: ContentLayout,
        children: [
          { path: "/register", component: Async(import("../page/statistics/Register")) },
          { path: "/order/payout", component: Async(import("../page/statistics/payout")) },
        ]
      },
      {
        path: '/log/*',
        layout: ContentLayout,
        children: [
          {
            path: '/opt',
            component: Async(import('../page/log/optRecods')),
          },
          {
            path: '/login',
            component: Async(import('../page/log/loginRecords')),
          },
        ],
      },
      {
        path: '/user/*',
        layout: ContentLayout,
        children: [
          {
            path: '/list',
            component: Async(import("../page/user/UserList")),
          },
          {
            path: '/balance/record',
            component: Async(import("../page/user/UserBalanceRecords")),
          },
          {
            path: '/game/record',
            component: Async(import("../page/user/UserGameRecords")),
          },
          {
            path: '/level',
            component: Async(import("../page/user/UserLevel")),
          },
          {
            path: '/tags',
            component: Async(import("../page/user/UserTag")),
          },
        ],
      },
      {
        path: '/money/*',
        layout: ContentLayout,
        children: [
          {
            path: '/record',
            component: Async(import('../page/money/index')),
          },
        ],
      },
    ],
  },
]

export default routes
