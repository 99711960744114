// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root,
.app-page {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

/* 滚动条优化 start
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius:2px;
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background: #666;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
} */




`, "",{"version":3,"sources":["webpack://./src/modules/admin/index.css"],"names":[],"mappings":"AAAA;;;;IAII,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;AACd;;AAEA;;;;;;;;;;;;;;;;;;;;;;GAsBG","sourcesContent":["html,\nbody,\n#root,\n.app-page {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n}\n\n/* 滚动条优化 start\n::-webkit-scrollbar {\n    width: 5px;\n    height: 5px;\n}\n\n::-webkit-scrollbar-track {\n    background-color: transparent;\n    border-radius:2px;\n}\n\n::-webkit-scrollbar-thumb {\n    background-color: transparent;\n    border-radius: 2px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n    background: #666;\n}\n\n::-webkit-scrollbar-corner {\n    background-color: transparent;\n} */\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
