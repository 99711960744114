// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ba-layout-app,
.ba-layout-body {
    height: 100%;
    width: 100%
}

.ba-layout-sider{
    /* background-color: transparent; */
    overflow-y: auto;
}
.ba-layout-header {
    /* background-color: #fff; */
    /* border-bottom: 1px solid #f0f0f0; */
    padding: 0;
    height: 48;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: transparent; */
    
}

.data-table-query-form>.ant-col {
    margin-bottom: 15px;
}

.data-table-query-form>:last-child{
    margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/modules/admin/layout/ba-layout.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ;AACJ;;AAEA;IACI,mCAAmC;IACnC,gBAAgB;AACpB;AACA;IACI,4BAA4B;IAC5B,sCAAsC;IACtC,UAAU;IACV,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mCAAmC;;AAEvC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".ba-layout-app,\n.ba-layout-body {\n    height: 100%;\n    width: 100%\n}\n\n.ba-layout-sider{\n    /* background-color: transparent; */\n    overflow-y: auto;\n}\n.ba-layout-header {\n    /* background-color: #fff; */\n    /* border-bottom: 1px solid #f0f0f0; */\n    padding: 0;\n    height: 48;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    /* background-color: transparent; */\n    \n}\n\n.data-table-query-form>.ant-col {\n    margin-bottom: 15px;\n}\n\n.data-table-query-form>:last-child{\n    margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
