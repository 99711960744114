import { StyleProvider } from '@ant-design/cssinjs';
import { App, ConfigProvider, theme } from "antd";
import zhCN from 'antd/locale/zh_CN';
import { useEffect, useState } from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import ThemeProiver from "../../public/components/ThemeProvier";
import { PageRoute, RenderRoute } from "../../public/module";
import "../../public/randomUtils";
import routes from "./config/routes";
import "./index.css";
// import dayjs from "dayjs";
import moment from "moment";
import "moment-timezone"
import 'moment/locale/zh-cn';
import TimezoneProiver, { setDefaultTimezone } from './public/TimezoneProvier';

moment.locale('zh-cn');


const AdminApp = (props: any) => {



    useEffect(() => {


    }, [])

    return (
        <>

            <StyleProvider>
                <ConfigProvider
                    locale={zhCN}
                    
                    theme={{
                        token: {
                            colorPrimary: "#6d2bed",
                        },
                        // algorithm: dark ? theme.darkAlgorithm : theme.defaultAlgorithm,

                    }}
                >
                    <App className={`app-page`}  >
                        <BrowserRouter>
                            <Routes>
                                {routes.map((r: PageRoute, i) => RenderRoute(r, i))}
                            </Routes>
                        </BrowserRouter>
                    </App>
                </ConfigProvider>
            </StyleProvider>
        </>
    )

}

const defaultTimezone = localStorage.getItem("default-tz-timezone");

if(defaultTimezone){
    moment.tz.setDefault(defaultTimezone);
}

export default (props: any) => {

    const [timezone,setTimezone] = useState<any>(defaultTimezone?defaultTimezone:moment.tz.guess());

    const onSetTimezone = (value:any)=>{

        // console.log("zone=>",value);

        // dayjs.tz.setDefault(value);

        setDefaultTimezone(value);

        localStorage.setItem("default-tz-timezone",value);


        setTimezone(value);
    }

    return (
        <>
            <TimezoneProiver timezone={{timezone:timezone,setTimezone:onSetTimezone}}>
                <AdminApp />
            </TimezoneProiver>
        </>
    )
};