import {
    AuditOutlined,
    BarChartOutlined,
    DownOutlined,
    EditOutlined,
    FileOutlined,
    GatewayOutlined,
    HomeOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PayCircleOutlined,
    PoundOutlined,
    SafetyCertificateOutlined,
    SafetyOutlined,
    SettingOutlined,
    TagsOutlined,
    UngroupOutlined,
    UserOutlined,
    VideoCameraOutlined,
    WindowsOutlined,
    RobotOutlined,
    WalletOutlined,
    ProjectOutlined,
    OrderedListOutlined,
    GroupOutlined,
    MessageOutlined,
    GiftOutlined,
    BankOutlined,
    MoneyCollectOutlined,
    UsergroupAddOutlined,
    AppstoreOutlined,
    GlobalOutlined
} from '@ant-design/icons';
import { App, Breadcrumb, Button, Dropdown, Form, Layout, Menu, MenuProps, Switch } from "antd";
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGetLogin } from "../../../public/components/AuthProvier";
import { useIsDark, useSetDark } from '../../../public/components/ThemeProvier';
import api from '../config/api';
import "./ba-layout.css";
import { useSetTimezone, useTimezone } from '../public/TimezoneProvier';
import TimezoneSelect from '../public/TimezoneSelect';
import { log } from 'console';

type MenuItem = Required<MenuProps>['items'][number];

const icons: any = {
    "home": HomeOutlined,
    "sys": SettingOutlined,
    "log": SafetyOutlined,
    "live": VideoCameraOutlined,
    "content": FileOutlined,
    "order": OrderedListOutlined,
    "game": GatewayOutlined,
    "user": UserOutlined,
    "integral": PoundOutlined,
    "payment": PayCircleOutlined,
    "family": UngroupOutlined,
    "operate": WindowsOutlined,
    "riskcontrol": EditOutlined,
    "activity": AuditOutlined,
    "statistics": BarChartOutlined,
    "robot": RobotOutlined,
    "wallet": WalletOutlined,
    "setting": ProjectOutlined,
    "pawn:comb": TagsOutlined,
    "group": GroupOutlined,
    "message": MessageOutlined,
    "lottery": GiftOutlined,
    "merchant": BankOutlined,
    "money":MoneyCollectOutlined,
    "agent":UsergroupAddOutlined,
    "brand":AppstoreOutlined,
    "site":GlobalOutlined
}

type MenuLayoutProps = {
    // menus?:MenuData[]
    onSelectMenus?: Function,
    login: any
}

const getTreeMenuData = (menus: any[], pid: any) => {

    const subMenus: any[] = menus.filter(m => m.type == 0).filter(x => x.parentId == pid);

    if (subMenus.length > 0) {
        subMenus.forEach(item => {

            const subs: any[] = getTreeMenuData(menus, item.id);
            item.children = subs;

        })
        return subMenus;
    }
    return subMenus;

}


const MenuLayout: FC<MenuLayoutProps> = ({ onSelectMenus, login }) => {



    const menus: any[] = [
        ...getTreeMenuData(login.menus, 0)
    ];

    const location = useLocation();

    const [openKeys, setOpenKeys] = useState<Array<string>>([]);



    const getPathArr = (paths: string) => {


        if (paths == "/") {
            return ["/"];
        }

        const pathArrs = [];
        const arrs = paths.split("/");
        let str = "";
        for (let i = 0; i < arrs.length; i++) {

            if (arrs[i]) {
                str += `/${arrs[i]}`;
                pathArrs.push(`${str}`);
            }

        }

        return pathArrs;

    }

    useEffect(() => {


        const defaultOpenKeys = getPathArr(location.pathname);

        setOpenKeys(defaultOpenKeys);

        if (onSelectMenus) {
            const loginMenus: any[] = login.menus;
            const selectMenus = defaultOpenKeys.map(x => loginMenus.find(s => s.path == x)).filter(item => item != undefined).map(item => item.name);
            onSelectMenus(selectMenus);

        }



    }, [location.pathname]);





    const rednerMenu: any = ((menu: any, level: number) => {

        const Icon: any = icons[`${menu.perms}`];

        const children: Array<any> = menu.children;

        const MenuIcon: any = Icon ? <Icon /> : null;

        if (children && children.length) {

            return {
                label: menu.name,
                key: menu.path,
                icon: MenuIcon,
                children: children.map((item, index) => rednerMenu(item, index)),
                onTitleClick: ((e: any) => {

                    console.log("openKeys=>", openKeys, e.key);

                    setOpenKeys([e.key]);

                    // const key = e.key;
                    // const find = openKeys.find(x => x == key);
                    // if (!find) {
                    //     openKeys.push(key);
                    // } else {
                    //     for (let i = 0; i < openKeys.length; i++) {
                    //         if (key == openKeys[i]) {
                    //             openKeys.splice(i, 1);
                    //         }
                    //     }
                    // }

                    // setOpenKeys([...openKeys])
                })
            }
        }

        return {
            label: <Link to={menu.path}>{menu.name}</Link>,
            key: menu.path,
            icon: MenuIcon,
        }
    })
    return (
        <Menu
            theme="light"
            style={{ height: "100%" }}
            defaultSelectedKeys={[location.pathname]}
            mode="inline"
            openKeys={openKeys}
            selectedKeys={openKeys}
            items={menus.map((item, index) => rednerMenu(item, index))}
            onOpenChange={keys => {

                if (keys.length == 0) {
                    setOpenKeys([]);
                }

            }}


        />


    )
}

const TimezoneProiverView = ({timezone, setTimezone}:any) => {

   

    const [timezoneVisable, setTimezoneVisable] = useState<any>(false);


    return (
        <div style={{ marginRight: 15 }}>
            <Dropdown
                open={timezoneVisable}
                trigger={["click"]}
               
                dropdownRender={() => {
                    return (
                        <>
                            <div style={{ maxWidth: 450, backgroundColor: "#fff", padding: 10,borderRadius:3, border: "1px solid #ddd", }}>
                                <Form
                                    style={{ marginTop: 20 }}
                                    initialValues={{
                                        zone: timezone
                                    }}
                                    onFinish={values => {
                                        setTimezone(values?.zone);
                                        setTimezoneVisable(false);
                                    }}
                                >
                                    <Form.Item label="时区" name="zone" rules={[{ required: true }]}>
                                        <TimezoneSelect style={{ width: 200 }} />
                                    </Form.Item>
                                    <Form.Item>
                                        <div>
                                            <Button type="default" onClick={() => setTimezoneVisable(false)} style={{ marginLeft: 50 }}>取消</Button>
                                            <Button htmlType="submit" type="primary" style={{ marginLeft: 15 }}>确定</Button>
                                        </div>

                                    </Form.Item>
                                </Form>

                            </div>
                        </>
                    )
                }}

                placement="bottomRight"
            >
                <div style={{ cursor: "default" }} onClick={() => setTimezoneVisable(true)}>
                    <span>当前时区:{timezone}</span>
                </div>
            </Dropdown>

        </div>
    )
}

const BaLayout: FC<PropsWithChildren> = ({ children }) => {


    const [collapsed, setCollapsed] = useState<boolean>(false);

    const [menuNames, setMenuNames] = useState<string[]>([]);

    const { modal } = App.useApp();

    const user = useGetLogin();

    const navigate = useNavigate();

    const dark = useIsDark();
    const setDark = useSetDark();

    const timezone:any = useTimezone();
    const setTimezone:any = useSetTimezone();

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    
    console.log("s=>",timezone);

    return (
        <Layout className='ba-layout-app'>
            <Layout.Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                className='ba-layout-sider'
                theme="light"
            >
                <MenuLayout login={user} onSelectMenus={(names: string[]) => {
                    setMenuNames(names);
                }} />
            </Layout.Sider>
            <Layout style={{ overflowY: "auto", width: "100%", height: window.document.body.clientHeight }}>
                <Layout.Header

                    className='ba-layout-header'
                    style={{
                        backgroundColor: dark ? "#141414" : "#ffffff",
                        paddingInline: 0,
                        borderBottom: dark ? "1px solid rgba(255,255,255,0.1)" : "1px solod #f0f0f0"
                    }}

                >

                    <div style={{ paddingLeft: 10, fontSize: 20, display: "flex" }}>

                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: toggle,
                        })}

                        <div style={{ paddingLeft: 10 }}>
                            <Breadcrumb className="layout-breadcrumb"

                                items={menuNames.map(name => ({
                                    key: name,
                                    title: name
                                }))}

                            />
                        </div>

                    </div>

                    <div style={{ display: "flex" }}>
                        <div>
                            <TimezoneProiverView timezone={timezone} setTimezone={setTimezone} />
                        </div>
                        <div style={{ paddingRight: 15 }}>
                            <Dropdown
                                trigger={["click", "hover"]}
                                menu={{
                                    items: [
                                        {
                                            key: "usercenter",
                                            label: <Link to={"/center/user"} >个人中心</Link>,
                                            icon: <UserOutlined />,


                                        },
                                        {
                                            key: "line-1",
                                            type: 'divider',
                                            dashed: true
                                        },
                                        {
                                            key: "restPassword",
                                            label: <Link to={"/center/rest-password"} >修改密码</Link>,
                                            icon: <SafetyCertificateOutlined />,


                                        },
                                        {
                                            key: "line-2",
                                            type: 'divider',
                                            dashed: true
                                        },
                                        {
                                            key: "logout",
                                            label: "退出登录",
                                            icon: <LogoutOutlined />,
                                            onClick: () => {

                                                modal.confirm({
                                                    title: "提示",
                                                    content: "是否退出登录",
                                                    onOk: () => {

                                                        api("user/logout").post({}).then(ret => {
                                                            if (ret.code == 200) {
                                                                navigate("/login")
                                                            }
                                                        })

                                                    }
                                                })

                                            },

                                        }

                                    ]
                                }}
                                placement="bottomRight"
                            >
                                <div>
                                    <span style={{ marginRight: 5, }}>
                                        <UserOutlined style={{ fontSize: 18 }} />
                                        <span style={{ marginLeft: 5 }}>
                                            {user?.userName}
                                        </span>

                                    </span>
                                    <DownOutlined />
                                </div>

                            </Dropdown>
                        </div>
                    </div>



                </Layout.Header>
                <Layout.Content style={{ overflowY: "auto", height: "100%" }}>
                    {children}
                </Layout.Content>

            </Layout>
        </Layout>
    )
}

export default BaLayout;